<template>
  <span>
    <span v-if="item.item.title">
      <img width="50" height="50" :src="item.item.thumbnail">
      <span class="text-primary">
        <strong>{{ formatMeliItemId(item.item.id) }} - {{ item.item.title }} </strong>
      </span>
      <span class="text-danger">
        <strong>{{ formatMoney(item.item.price) }} </strong>
      </span>
      <span>x {{ item.item.available_quantity }} {{ $t('components.cards.meli.questions.available') }} </span>
      <b-link :id="'lnkItem' + item.id" :href="item.item.permalink" target="blank">
        <font-awesome-icon :icon="['fas', 'external-link-alt']" fixed-width />
      </b-link>
      <b-tooltip :target="'lnkItem' + item.id" triggers="hover" placement="bottom">
        {{ $t('components.cards.meli.questions.view_ml') }}
      </b-tooltip>
      <br>
      <hr>
      <span v-for="question in item.questions" :key="question.id">
        <question :question="question" :answerConfiguration="answerConfiguration"
          :answerTemplates="answerTemplates" :isPayerUser="isPayerUser"
          :options="options" :selectedAccount="selectedAccount"/>
          <hr>
      </span>
    </span>
  </span>
</template>

<script>
import {
  VBTooltip, BLink, BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import stringHelper from '@core-md/mixins/stringHelper'
import meliHelper from '@core-md/mixins/meliHelper'
import Question from './Question.vue'

export default {
  components: {
    BLink,
    BTooltip,
    Question,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    answerConfiguration: {
      type: Object,
      default: null,
    },
    answerTemplates: {
      type: Array,
      default: null,
    },
    isPayerUser: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: null,
    },
    selectedAccount: {
      type: Object,
      default: null,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [
    stringHelper,
    meliHelper,
  ],
  data() {
    return {
      internalShowDetail: false,
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>
